
import { Delete, Edit, View, Search } from "@element-plus/icons-vue";
import { getCookie, setCookie } from "../../utils/tools";
import { client } from '@/client';
import { ElMessage, ElMessageBox } from 'element-plus';
import { defineComponent } from 'vue';
import { OrderBy } from "@/shared/types/BaseType";
import { ShopData, ShopDataType } from "@/shared/types/ShopData";
import { ReqList } from "@/shared/adminprotocols/shop/PtlList";
export default defineComponent({
    name: "admin-index",
    setup() {
      return {
        Edit,
        Delete,
        View,
        Search,
      };
    },
    data: () => {
      return {
        roles: [{label:"全部",value:'0'},{label:"金币",value:ShopDataType.GOLD},{label:"贝壳",value:ShopDataType.CONCH}],
        roleSelected:['0'] as any[],
        keywords: "",
        orderBy:{
            sort:"ctime" as keyof ShopData,
            order:"descending" as OrderBy
        },
        tableData:[] as ShopData[],
        multipleSelection: [] as any[],
        count: 0,
        cur: 1,
        username: "",
        pageSize:10
      };
    },
    computed: {},
    created() {
      this.username = getCookie("username")||"";
      this.list();
    },
    methods: {
      handleChange(){
          console.error(this.roleSelected[0]);
      },
      clearSearch() {
        this.$router.replace({
          name: "shop-index",
          query: { cur: 1, cid: 0, keywords: "" },
        });
      },
      doSearch() {
        this.$router.replace({
          name: "shop-index",
          query: { cur:1, keywords: this.keywords },
        });
        this.list();
      },
      //查询
      async list() {
        try {
            let orderData:any = {sort:this.orderBy.sort}
            if (this.orderBy.order) {
                orderData.order = this.orderBy.order =="ascending"?"ASC" : "DESC";
            }
            let data:ReqList = {cur:this.cur,pageSize:this.pageSize,orderBy:orderData};
            let type = this.roleSelected[0];
            if (type != 0) {
                data.type = type;
            }
            if(this.keywords != ""){
                data.key = this.keywords;
            }
          let res = await client.callApi("shop/List",data);
          if (res.isSucc) {
              this.tableData = [...res.res.list];
            this.count = res.res.count;
          }
        } catch (error) {
          console.log(error);
        }
      },
  
      //翻页
      handleCurrentChange(e: number) {
        this.cur = e;
        this.list();
      },
  
      toggleSelection(rows: any[]) {
            let refs:any = this.$refs.multipleTable;
        if (rows) {
          rows.forEach((row: any) => {
            if (refs) {
              refs.toggleRowSelection(row);
            }
          });
        } else {
          refs.clearSelection();
        }
      },
  
      handleSelectionChange(val: never[]) {
        this.multipleSelection = val;
      },
  
      //编辑
      toEdit(e: { id: any; }) {
        let id = e.id;
        this.$router.push({ name: "shop-edit", params: { id: id } });
      },
  
    delSome() {
        let ids = this.multipleSelection.map((item) => {
        return parseInt(item.id);
        });
        ElMessageBox.confirm("此操作将永久删除, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        })
        .then(async () => {
        let res = await client.callApi("shop/Del",{ids:ids});
        if (res.isSucc) {
            ElMessage({
                message: "删除成功 ^_^",
                type: "success",
            });
            this.list();
            }
        })
        .catch(() => {
            ElMessage({
            type: "info",
            message: "已取消删除",
            });
        });
    },
        handleSizeChange(val: { pageSize: number; }){
        this.pageSize = val.pageSize;
        this.list();
        },
  
      //删除
      async handleDel(e: { id: any; }) {
        let id = e.id;
        try {
           ElMessageBox.confirm("这么疯狂，要删数据？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              let res = await client.callApi("shop/Del",{ids:[id]});
              // let res = await del(id);
              if (res.isSucc) {
                ElMessage({
                  message: "删除成功 ^_^",
                  type: "success",
                });
                this.list();
              }
            })
            .catch(() => {
              ElMessage({
                type: "info",
                message: "已取消删除",
              });
            });
        } catch (error) {
          console.log(error);
        }
      },
        handleChangeSort(e:{prop:keyof ShopData,order:'descending'|"ascending"|null}){
            console.error(e);
            this.orderBy = {
                sort:e.prop,
                order:e.order
            }

            this.list();
        }
    },
  });
  